.formMainDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    margin-top: 80px;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--primary);
    height: 65px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
  }
  
  .logo {
    height: 40px;
    filter: brightness(0) invert(1);
  }
  
  .headersDiv {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: row wrap;
    margin-bottom: 40px;
  }
  
  .fs14 {
    font-size: 14px;
  }
  
  .btnIcon {
    margin-right: 8px;
    margin-bottom: 2px;
  }
  
  @media (min-width: 768px) {
    .hint {
      display: none;
    }
  }
  