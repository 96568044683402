//
// Aside Default
//

// General mode(all devices)
.aside {
  display: flex;
  flex-direction: column;
  box-shadow: var(--kt-aside-box-shadow);
  padding: 0;
  background-color: #131313;

  // Custom button
  .btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .btn-label {
      transition: opacity get($aside-config, transition-speed) ease;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      opacity: 1;
    }

    .btn-icon {
      display: inline-block;
      transition: opacity get($aside-config, transition-speed) ease;
      overflow: hidden;
      opacity: 0;
      margin: 0;
      width: 0;
    }
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .aside {
    width: get($aside-config, width);
    transition: width get($aside-config, transition-speed) ease;

    // Logo
    .aside-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 120px;
      padding: 0 get($aside-config, padding-x);
      background-color: #010304;
    }

    // Aside menu
    .aside-menu {
      width: get($aside-config, width);
    }

    // Fixed aside mode
    .aside-fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: get($aside-config, z-index);
      overflow: hidden;
    }

    // Static aside mode
    .aside-static & {
      position: relative;
      z-index: 1;
    }

    // Minimize aside mode
    [data-kt-aside-minimize="on"] & {
      width: get($aside-config, minimized-width);
      transition: width get($aside-config, transition-speed) ease;

      // Logo
      .aside-logo {
        .logo-default {
          display: inline-block;
        }

        .logo-minimize {
          display: none;
        }
      }

      // Hoverable
      &.aside-hoverable:hover:not(.animating) {
        transition: width get($aside-config, transition-speed) ease;
        width: get($aside-config, width);
        box-shadow: var(--kt-aside-minimized-hover-box-shadow);
      }

      // Not hovered mode
      &:not(.aside-hoverable),
      &:not(:hover) {
        // Logo
        .aside-logo {
          justify-content: center;

          .logo {
            display: none;
          }

          .logo-default {
            display: none;
          }

          .logo-minimize {
            display: inline-block;
          }
        }

        // Menu
        .aside-menu {
          .menu-content,
          .menu-title {
            opacity: 0;
            transition: opacity get($aside-config, transition-speed) ease;
          }

          .menu-item.show > .menu-sub {
            height: 0;
            overflow: hidden;
            transition: height get($aside-config, transition-speed) ease;
          }
        }

        // Custom button
        .btn-custom {
          .btn-label {
            width: 0;
            opacity: 0;
            transition: opacity get($aside-config, transition-speed) ease;
          }

          .btn-icon {
            width: auto;
            opacity: 1;
            transition: opacity get($aside-config, transition-speed) ease;
          }
        }
      }
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .aside {
    display: none;

    // Logo
    .aside-logo {
      display: none;
    }
  }
}
