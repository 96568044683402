.left {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: left;
  justify-content: space-around;
}

.right {
  height: 100vh;
  padding: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: 0px;
  color: white;
  text-decoration: none !important;
  margin-left: 0.25rem;
}

.footer:hover {
  color: lightgrey;
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .left {
    display: none;
  }
  .right {
    height: auto;
  }

  .footer {
    color: var(--primary);
    display: none;
  }
  .footer:hover {
    color: var(--primary);
  }
}

.titles > h1 {
  font-size: 3vw;
  font-weight: 600;
  color: white;
  padding: 0 10px;
  text-transform: uppercase;
}

.titles > h2 {
  font-size: 18px;
  font-weight: 400;
  color: white;
  padding: 0 10px;
}

.form {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.logo {
  object-fit: contain;
  max-width: 260px;
  max-height: 100%;
  width: auto;
  height: auto;
}

.notFound {
  text-align: center;
  width: 100%;
  margin-top: 20px !important;
  font-size: large;
}
